
/**
 * Since some configurations need to be match with specific themes, 
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    "themeColor": "emerald",
    "direction": "ltr",
    "mode": "light",
    "locale": "ru",
    "primaryColorLevel": 800,
    "cardBordered": true,
    "panelExpand": false,
    "controlSize": "md",
    "navMode": "themed",
    "layout": {
        "type": "modern",
        "sideNavCollapse": false
    }
}
